//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//
// base font-size
$font-size-base: 1rem !default; // Assumes the browser default, typically `13px`
$font-weight-normal: 500 !default;
$font-weight-light: 400 !default;
$font-weight-lighter: 300 !default;
$font-weight-extra-light: lighter !default;

// Heading sizes
$h1-font-size: $font-size-base * 1.45 !default; // 18.85px
$h2-font-size: $font-size-base * 1.32 !default; // 17.16px
$h3-font-size: $font-size-base * 1.26 !default; // 16.38x
$h4-font-size: $font-size-base * 1.2 !default; // 15.6px
$h5-font-size: $font-size-base * 1.1 !default; // 14.3px
$h6-font-size: $font-size-base * 1.0 !default; // 13.0px

// Theme colors
// Primary
$white: #ffffff !default;
$black: #000000 !default;
$primary: #E85936 !default; // Bootstrap variable
$gray-heading: #464E5F !default; // Bootstrap variable
$blue-heading: #3699FF !default;
$brown-heading: #4C3D3A !default;
$card-gray: #F3F6F9 !default;
$border-radius-xl: 1rem !default;
$primary-active: #cc3813;
$primary-light: #E8593666;
$primary-light-dark: #212e48;
$primary-inverse: #ffffff;

// Success
$success: #50cd89;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: #ffffff;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: #ffffff;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: #ffffff;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: #ffffff;

//grey
$gray-100: #f5f8fa !default;
$gray-200: #eff2f5 !default;
$gray-300: #E4E6EF !default;
$gray-400: #B5B5C3 !default;
$gray-500: #A1A5B7 !default;
$gray-600: #7E8299 !default;
$gray-700: #5E6278 !default;
$gray-800: #3F4254 !default;
$gray-900: #181C32 !default;

//grey dark
$gray-100-dark: #1b1b29 !default;
$gray-200-dark: #2B2B40 !default;
$gray-300-dark: #323248 !default;
$gray-400-dark: #474761 !default;
$gray-500-dark: #565674 !default;
$gray-600-dark: #6D6D80 !default;
$gray-700-dark: #92929F !default;
$gray-800-dark: #CDCDDE !default;
$gray-900-dark: #FFFFFF !default;

// Light
$light: $gray-100 !default;
$light-dark: $gray-200-dark !default;
$light-active: $gray-200 !default;
$light-active-dark: $gray-300-dark !default;
$light-light: gba($gray-100, 0.75) !default;
$light-inverse: $gray-600 !default;
$light-inverse-dark: $gray-600-dark !default;

//secondary
$secondary: #F5F8FA;
$secondary-dark: #EF5B2D33;
$secondary-active: #EF5B2D33;
$secondary-active-dark: #EF5B2D66;
$secondary-light: #EF5B2D99;
$secondary-light-dark: #EF5B2D22;
$secondary-inverse: #EF5B2DDD;
$secondary-inverse-dark: #EF5B2D99;

$card-box-shadow: none !default;
$card-border-dashed-color: $primary !default;
$card-border-radius: $border-radius-xl !default;
$card-py: 1.5rem !default;
$card-px: 1.5rem !default;
$headings-font-weight: 500 !default;

$donut-colors: (
	0: #00C0CC,
	1: #258D00,
	2: #FFA600,
	3: #FF7C43,
	4: #F95D6A,
	5: #D45087,
	6: #A05195,
	7: #665191,
	8: #2F4B7C,
	9: #003F5C,
	) !default;

// dark
$dark: $gray-900 !default;
$dark-dark: $gray-900-dark !default;
$dark-active: darken($gray-900, 3%) !default;
$dark-active-dark: lighten($gray-900-dark, 3%) !default;
$dark-light: $gray-200 !default;
$dark-light-dark: $gray-200-dark !default;
$dark-inverse: $white !default;
$dark-inverse-dark: $gray-100-dark !default;

$text-muted: $gray-400 !default;
$text-muted-dark: $gray-400-dark !default;

$theme-colors: (
	"white": $white,
	// custom color type
	"light": $light,
	"primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"dark": $dark,
	"gray-heading": $gray-heading,
	"primary-light": $primary-light,
	"card-gray": $card-gray) !default;

$theme-colors-dark: (
	"dark": $dark-dark,
	"light": $light-dark,
	"secondary": $secondary-dark,
	"card-gray": $light-dark
) !default;

$theme-text-colors: (
	"white": $white,
	"primary": $primary,
	"secondary": $secondary,
	"light": $light,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"dark": $dark,
	"muted": $text-muted,
	"gray-100": $gray-100,
	"gray-200": $gray-200,
	"gray-300": $gray-300,
	"gray-400": $gray-400,
	"gray-500": $gray-500,
	"gray-600": $gray-600,
	"gray-700": $gray-700,
	"gray-800": $gray-800,
	"gray-900": $gray-900,
	"gray-heading": $gray-heading) !default;

$card-border-radius: $border-radius-xl !default;

$gray-900: #464E5F;
$headings-color: $gray-900 !default;
$headings-color-dark: $gray-800-dark !default;
$body-color-dark: $gray-800-dark !default;

$display-font-sizes: (
	1: 5rem,
	2: 4.5rem,
	3: 4rem,
	4: 3.5rem,
	5: 3rem,
	6: 2.5rem,
	7: 1.8rem,
	) !default;
$display-font-weight: $font-weight-normal !default;

$component-active-color-dark:   $primary-light-dark !default;