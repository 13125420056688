//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base";
@import "layout/layout";

.card-dashed {
  width: 383.5px;
}

.wl-odd-row {
  background: linear-gradient(rgba(0, 59, 207, 0.25), var(--kt-body-bg))
}

.wl-even-row {
  background: linear-gradient(rgba(248, 178, 155, 0.45), var(--kt-body-bg))
}

.card-hover:hover{
  border: 2px $card-border-style var(--kt-primary);
}

.row-hover:hover{
  border: 2px $card-border-style var(--kt-primary);
  border-radius: $card-border-radius;
}

.gradientHissa {
  background: linear-gradient(144.87deg,var(--kt-primary) -45.4%, var(--kt-primary-light) 99.13%)
}